<template>
  <va-card>
    <div class="row">
      <div class="flex xs12 md5">
        <va-input
          color="info"
          v-model='nameQuery'
          :disabled='loading'
          :label="$t('layout.form.nameInput')"
        />
      </div>
      <div class="flex xs12 md5">
        <va-select
          multiple
          searchable
          v-model="typeQuery"
          key-by="value"
          text-by="name"
          :label="$t('resources.inputs.typeInput')"
          :options="typeOptions"
          :loading="loading"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
        />
      </div>
      <div
        class="flex xs12 md6"
      >
        <va-select
          v-model="regionQuery"
          :label="$t('layout.form.regionInput')"
          :no-options-text="$t('layout.empty')"
          :loading="isLoading.regions"
          :disabled="loading"
          :options="regionsList"
          key-by="id"
          text-by="i18nName"
          searchable
        />
      </div>
      <div class="flex xs12 md5">
        <va-select
          v-model="languageQuery"
          :label="$t('layout.selects.select_a_language')"
          :options="LanguagesLists"
          :no-options-text="$t('layout.empty')"
          key-by="id"
          :disabled="loading"
          text-by="name"
        />
      </div>
      <div class="flex xs12 md1 offset--md1">
        <va-popover
          :message="$t('layout.filters.button')"
          placement="up"
        >
          <va-button
            small
            flat
            color="primary"
            icon="fa fa-search"
            :disabled="loading"
            @click.prevent="submit"
          />
        </va-popover>
      </div>
    </div>
  </va-card>
</template>

<script>
export default {
  name: 'search-bar',
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data () {
    return {
      isLoading: {
        regions: false,
      },
      isError: {
        regions: false,
      },
      LanguagesLists: [],
      regionsList: [],
      nameQuery: '',
      regionQuery: 0,
      typeQuery: [],
      languageQuery: '',
    }
  },
  computed: {
    hasTags () {
      return this.typeQuery.length > 0
    },
    typeOptions () {
      const types = [
        {
          name: this.$t('resources.types.docx'),
          value: 0,
        },
        {
          name: this.$t('resources.types.xlsx'),
          value: 1,
        },
        {
          name: this.$t('resources.types.pptx'),
          value: 7,
        },
        {
          name: this.$t('resources.types.pdf'),
          value: 2,
        },
        {
          name: this.$t('resources.types.jpg'),
          value: 3,
        },
        {
          name: this.$t('resources.types.mp4'),
          value: 4,
        },
        {
          name: this.$t('resources.types.mp3'),
          value: 5,
        },
        {
          name: this.$t('resources.types.unknown'),
          value: 6,
        },
      ]

      return types
    },
  },
  created () {
    this.asyncFindRegion('&sort=name&direction=asc')
    this.getRemoteLangs()
  },
  methods: {
    submit () {
      let query = `&q=${this.nameQuery}`
      if (this.typeQuery.length > 0) {
        query += '&type='
        const types = this.typeQuery.map(t => t.value)
        query += types.join()
      }
      query += '&language=' + this.languageQuery.id
      if (this.regionQuery) {
        query += '&region=' + this.regionQuery.id
      }
      this.$emit('submit', query)
    },
    async getRemoteLangs () {
      let response = false
      try {
        response = await this.$http.get('/langs/list?type=0&skipData=true')
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        setTimeout(() => {
          if (this.retry < 4) {
            this.retry++
            this.getRemoteLangs()
          }
        })
        return
      }

      const arr = response.data.data
      arr.forEach(p => {
        p.name = this.$t('language.' + p.name)
        this.LanguagesLists.push(p)
      })
    },
    hasAll (selector) {
      return this.allSelectors.some(s => s === selector)
    },
    async asyncFindRegion (query) {
      await this.asyncFind(query, 'regions', 'regions')
      if (this.isError.regions) {
        this.asyncFindRegion(query)
        return
      }
      for (const c of this.regionsList) {
        c.i18nName = this.$t(c.name)
      }

      if (this.hasAll('regions')) {
        this.regionsList.unshift({
          id: 0,
          i18nName: this.$t('layout.selects.all_regions'),
        })
      }
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = true
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
  },
}
</script>

<style>
</style>
